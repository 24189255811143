@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 100 900;
  src: url("/fonts/PretendardVariable.woff2") format("woff2-variations");
}

* {
  font-family: "Pretendard Variable", sans-serif;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

:root {
  --rsbs-backdrop-bg: rgba(26, 26, 26, 0.8);
  --rsbs-handle-bg: "transparent";
}

input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  background-image: url("/images/clear-icon.svg");
}

.animate-route {
  animation: route 0.9s ease-in-out infinite;
}

@keyframes route {
  0% {
    height: 0;
    opacity: 1;
  }
  56%,
  78% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 100%;
    opacity: 0;
  }
}
